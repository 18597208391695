export default function HeroSection() {
  return (
    <section id="heroSection" className="hero--section">
      <div className="hero--section--content--box">
        <div className="hero--section--content">
          <p className="section--title">CAPTURE, ACCURATE, DATA </p>
          <h1 className="hero--section--title">
            <span className="hero--section-title--color">INSTANTLY</span> <br />
          </h1>
          <p className="hero--section-description">
            This app is an iOS application designed for 500 Billion turnover
            construction company. If the users are working in site office
            without internet connectivity, that allows users to create, update,
            and delete timesheets smoothly, incorporating comprehensive offline
            to online sync capabilities to maintain data integrity and
            accessibility.
            <br />
          </p>
        </div>
        <button className="btn btn-primary">I Want A Trail</button>
      </div>
      <div className="hero--section--img">
        <img src="./img/6_.png" alt="Hero Section" />
      </div>
    </section>
  );
}
